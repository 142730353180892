import {
  RouteObject,
  unstable_usePrompt as usePrompt,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Control, useFieldArray, useForm } from 'react-hook-form'
import { Sections } from '../../../../../components/component/Sections/Sections.tsx'
import { Card } from '../../../../../components/component/Card/Card.tsx'
import { InputWrapper } from '../../../../../components/input/InputWrapper/InputWrapper.tsx'
import {
  FloatInput,
  TextInput,
} from '../../../../../components/input/TextInput/TextInput.tsx'
import { Button } from '../../../../../components/component/Button/Button.tsx'
import {
  IconAbc,
  IconAlertTriangle,
  IconAlignLeft,
  IconBraces,
  IconCheck,
  IconCircleDotted,
  IconEyeglass,
  IconGripVertical,
  IconHash,
  IconHelpCircleFilled,
  IconPlus,
  IconToggleRight,
  IconTrash,
} from '@tabler/icons-react'
import style from './Variations.module.scss'
import { IconButton } from '../../../../../components/component/IconButton/IconButton.tsx'
import { CSSProperties, FC, forwardRef, ReactNode } from 'react'
import { useFormDefaultValue } from '../../../../../hooks/useFormDefaultValue.ts'
import { graphql } from '../../../../../gql'
import { Tooltip } from '../../../../../components/component/Tooltip/Tooltip.tsx'
import { SelectInput } from '../../../../../components/input/SelectInput/SelectInput.tsx'
import { Dropdown } from '../../../../../components/component/DropdownMenu/DropdownMenu.tsx'
import cx from 'classnames'
import { Toolbar } from '../../../../../components/component/Toolbar/Toolbar.tsx'
import styleConditions from './Conditions.module.scss'
import { useFormSubmitMutation } from '../../../../../hooks/useFormSubmitMutation.ts'
import { toast } from 'react-hot-toast'
import { JsonInput } from '../../../../../components/input/JsonInput/JsonInput.tsx'
import { useCurrentOrgSafe } from '../../../../../hooks/useCurrentOrgSafe.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  ChangeRequestRequirement,
  ManageFlagsRole,
  ReviewsRole,
} from '../../../../../gql/graphql.ts'
import { getMovedItem } from '../../../../../utils/reorder.ts'
import { DragControls, motion, Reorder, useDragControls } from 'framer-motion'
import { confirm } from '../../../../../components/component/Modal/Modal.tsx'
import { gqlClient } from '../../../../../auth'

enum ValuesType {
  NULL = 'NULL',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  JSON = 'JSON',
}

const colors = [
  '#8f8f8f',
  '#f44336',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#009688',
  '#4caf50',
  '#ffc107',
  '#ff9800',
]

const safeJSONParse = (value: string | null) => {
  try {
    return JSON.parse(value ?? '')
  } catch (e) {
    return null
  }
}

export const VariationColorInput = forwardRef<
  any,
  {
    value: string
    onChange: (value: string) => void
  }
>(({ value, onChange }, ref) => {
  return (
    <Dropdown
      placement="top-start"
      mainAxisOffset={13}
      alignmentAxisOffset={-9}
      className={style.colorSwatchDropdown}
      component={forwardRef(({ onClick, ...props }, ref) => (
        <div
          {...props}
          ref={ref}
          onClick={(e) => {
            e.preventDefault()
            onClick(e)
          }}
          className={style.colorIndicator}
          style={{ ['--color']: value } as CSSProperties}
        />
      ))}
      ref={ref}
    >
      <div className={style.colorSwatch}>
        {colors.map((color, i) => (
          <div key={i}>
            <motion.div
              key={color}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: i * 0.02,
                type: 'spring',
                stiffness: 700,
                damping: 20,
              }}
              className={cx(
                style.colorIndicator,
                color === value && style.active
              )}
              style={{ ['--color']: color } as CSSProperties}
              onClick={() => onChange(color)}
            />
          </div>
        ))}
      </div>
    </Dropdown>
  )
})

const Item: FC<{
  render: (controls: DragControls) => ReactNode
}> = ({ render }) => {
  const controls = useDragControls()

  return render(controls)
}

const Page = () => {
  const { organization, project } = useCurrentOrgSafe()
  const { flagId } = useParams()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      valuesType: ValuesType.NULL,
      inactive: {
        id: null as string | null,
        name: '',
        description: '',
        color: colors[0],
      },
      active: [
        {
          id: null as string | null,
          name: '',
          description: '',
          color: colors[1],
          used: false,
          value: null as any,
        },
      ],
    },
  })

  const valuesType = watch('valuesType')

  const { data: flagData } = useFormDefaultValue({
    control,
    queryKey: ['flag', flagId, 'variations default values'],
    request: graphql(`
      query updateFlagVariationsDefaultData($id: ID!) {
        flag(id: $id) {
          id
          sharedWithMe
          allowInactiveVariations
          slug
          variations {
            id
            name
            description
            active
            used
            value
            color
          }
        }
      }
    `),
    variables: { id: flagId as string },
    mapDefaultValues: (data) => {
      let valuesType = ValuesType.NULL

      data.flag?.variations.forEach(({ active, value }) => {
        if (!active || value === null) {
          return
        }
        if (typeof value === 'number') {
          valuesType =
            valuesType === ValuesType.NUMBER || valuesType === ValuesType.NULL
              ? ValuesType.NUMBER
              : ValuesType.JSON
        }
        if (typeof value === 'string') {
          valuesType =
            valuesType === ValuesType.STRING || valuesType === ValuesType.NULL
              ? ValuesType.STRING
              : ValuesType.JSON
        }
        if (typeof value === 'boolean') {
          valuesType =
            valuesType === ValuesType.BOOLEAN || valuesType === ValuesType.NULL
              ? ValuesType.BOOLEAN
              : ValuesType.JSON
        }
        if (typeof value === 'object') {
          valuesType = ValuesType.JSON
        }
      })

      if (
        !data.flag?.allowInactiveVariations &&
        valuesType === ValuesType.NULL
      ) {
        valuesType = ValuesType.JSON
      }

      return {
        valuesType,
        inactive: data.flag?.variations.find(({ active }) => !active) ?? {
          id: null,
          name: '',
          description: '',
          color: colors[0],
        },
        active:
          data.flag?.variations
            .filter(({ active }) => active)
            .map((variation) => ({
              ...variation,
              value:
                valuesType === ValuesType.JSON
                  ? JSON.stringify(variation.value, null, 2)
                  : variation.value,
            })) ?? [],
      }
    },
  })

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'active',
  })

  usePrompt({
    when: isDirty,
    message: 'You have unsaved changes. Are you sure you want to leave?',
  })

  const queryClient = useQueryClient()

  const { mutateAsync: migrateFlag } = useMutation({
    mutationFn: (id: string) =>
      gqlClient.request(
        graphql(`
          mutation migrateFlag($id: ID!) {
            flag(id: $id) {
              transitionParadigm {
                id
              }
            }
          }
        `),
        { id }
      ),
  })

  const mapVariables = (
    variations: typeof control extends Control<infer D> ? D : never
  ) => {
    const activeVariations = variations.active.map(
      ({ id, name, description, value, color }) => ({
        id,
        name,
        description,
        active: true,
        value:
          variations.valuesType === ValuesType.JSON
            ? safeJSONParse(value)
            : value,
        color,
      })
    )

    return {
      id: flagId as string,
      variations: flagData?.flag?.allowInactiveVariations
        ? [
            {
              id: variations.inactive.id,
              name: variations.inactive.name,
              description: variations.inactive.description,
              active: false,
              value: null,
              color: variations.inactive.color,
            },
            ...activeVariations,
          ]
        : activeVariations,
    }
  }
  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateFlagVariations(
        $id: ID!
        $variations: [UpdateFlagVariation!]!
      ) {
        flag(id: $id) {
          updateVariations(variations: $variations) {
            id
            variations {
              id
              name
              description
              active
              value
              color
            }
          }
        }
      }
    `),
    mapVariables,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['flag', flagId] })
      toast.success('Variations updated')
    },
  })

  const navigate = useNavigate()

  const saveChangeRequest = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation updateFlagChangeRequestVariations(
        $id: ID!
        $variations: [UpdateFlagVariation!]!
      ) {
        flag(id: $id) {
          createChangeRequest(
            conditions: null
            information: null
            variations: $variations
          ) {
            id
          }
        }
      }
    `),
    mapVariables,
    onSuccess: (data) => {
      toast.success('Review created')
      queryClient.invalidateQueries({
        queryKey: ['flag', flagId],
      })
      setTimeout(
        () =>
          navigate(
            `/project/${project.slug}/feature-flags/${flagId}/reviews/${data.flag?.createChangeRequest.id}`
          ),
        100
      )
    },
  })

  usePrompt({
    when: isDirty,
    message: 'You have unsaved changes. Are you sure you want to leave?',
  })

  return (
    <form
      onSubmit={handleSubmit(
        organization.myAggregatedRole.manageFlags !== ManageFlagsRole.None
          ? submit
          : () => null
      )}
      style={{ marginTop: 24 }}
    >
      <Sections titleColumnWidthFr={0.4}>
        {flagData?.flag?.allowInactiveVariations && (
          <Sections.Section
            title="Fallback"
            subTitle={
              <Card
                color="yellow"
                variant="outlined-thick"
                style={{ marginTop: 10 }}
              >
                <p>
                  Fallback variations are now <b>deprecated</b>, we recommend
                  using exclusively active variations bellow and using the{' '}
                  <code>.get()</code> method in your code rather than{' '}
                  <code style={{ textDecoration: 'line-through' }}>
                    .isActive()
                  </code>
                </p>
                <Button
                  style={{ marginTop: 14 }}
                  to="https://tggl.io/blog/simplifying-tggl-to-enforce-good-practices"
                  target="_blank"
                >
                  Learn more
                </Button>
                <Button
                  style={{ marginTop: 8 }}
                  color="primary"
                  onClick={() =>
                    confirm({
                      title: 'Migrate flag',
                      confirmText: 'Migrate now',
                      description: (
                        <>
                          <p>
                            You are about to migrate this flag to the new,
                            simpler paradigm.
                          </p>
                          <h3 style={{ marginTop: 16 }}>
                            Checklist before migrating
                          </h3>
                          <ul style={{ marginTop: 8 }}>
                            <li style={{ marginBottom: 8 }}>
                              Read our{' '}
                              <a
                                href="https://tggl.io/blog/simplifying-tggl-to-enforce-good-practices#how-to-transition-old-flags"
                                target="_blank"
                              >
                                blog article
                              </a>{' '}
                              about this process
                            </li>
                            <li style={{ marginBottom: 8 }}>
                              Create a new active variation to replace the{' '}
                              <b>{getValues().inactive.name}</b> variation, and
                              make sure to replace it in the <b>conditions</b>{' '}
                              tab
                            </li>
                            <li>
                              Make sure that your code does not use{' '}
                              <code style={{ textDecoration: 'line-through' }}>
                                .isActive('
                                {flagData?.flag?.slug ?? 'my-feature'}')
                              </code>{' '}
                              as it will always return true after the migration,
                              use{' '}
                              <code>
                                .get('
                                {flagData?.flag?.slug ?? 'my-feature'}', false)
                              </code>{' '}
                              instead
                            </li>
                          </ul>

                          <h3 style={{ marginTop: 16 }}>
                            What will happen when I click "migrate"?
                          </h3>
                          <p style={{ marginTop: 8 }}>
                            The <b>{getValues().inactive.name}</b> variation
                            will be deleted and the flag will be marked as
                            migrated.
                          </p>
                          <h3 style={{ marginTop: 16 }}>
                            Can I rollback this change?
                          </h3>
                          <p style={{ marginTop: 8 }}>
                            Yes, you can go to the <b>history</b> tab and
                            rollback this change you are about to make.
                          </p>
                        </>
                      ),
                    }).then(() =>
                      toast.promise(
                        migrateFlag(flagId as string).then(() => {
                          queryClient.invalidateQueries({
                            queryKey: ['flag', flagId],
                          })
                        }),
                        {
                          loading: 'Migrating flag...',
                          success: 'Flag migrated',
                          error: (error) => {
                            if (
                              error.response?.errors?.[0]?.extensions?.code ===
                              'VARIATION_IN_USE'
                            ) {
                              return `The ${
                                getValues().inactive.name
                              } variation is still in use`
                            }

                            return 'Failed to migrate flag'
                          },
                        }
                      )
                    )
                  }
                >
                  Migrate flag
                </Button>
              </Card>
            }
          >
            <Card>
              <div className={style.cardHeader}>
                <InputWrapper
                  placeholder="Name"
                  name={`inactive.name`}
                  component={TextInput}
                  control={control}
                  rules={{ required: true }}
                  prefix={
                    <InputWrapper
                      name={`inactive.color`}
                      component={VariationColorInput}
                      control={control}
                    />
                  }
                />
                {valuesType !== ValuesType.NULL && (
                  <span className={style.hardCodedValue}>
                    Hard-codded value{' '}
                    <Tooltip>
                      <Tooltip.Trigger
                        component={IconHelpCircleFilled}
                        size={14}
                      />
                      <Tooltip.Content>
                        This value must be edited in the code directly and will
                        be used if anything goes wrong with Tggl
                      </Tooltip.Content>
                    </Tooltip>
                  </span>
                )}
              </div>
              <InputWrapper
                placeholder="Description"
                name={`inactive.description`}
                component={TextInput}
                control={control}
                prefix={
                  <Tooltip>
                    <Tooltip.Trigger component={IconAlignLeft} size={16} />
                    <Tooltip.Content>
                      A short description for your team
                    </Tooltip.Content>
                  </Tooltip>
                }
              />
            </Card>
          </Sections.Section>
        )}
        {organization.hasFlagsWithInactiveVariations &&
          !flagData?.flag?.allowInactiveVariations && (
            <Sections.Section title="Fallback">
              <Card>
                <p>
                  Fallback variations are now <b>deprecated</b>, this flag uses
                  exclusively active variations. Make sure to use the{' '}
                  <code>.get()</code> method in your code to retrieve the
                  variation's value rather than{' '}
                  <code style={{ textDecoration: 'line-through' }}>
                    .isActive()
                  </code>{' '}
                  which will always return <code>true</code> for this flag.
                </p>
                <Button
                  style={{ marginTop: 14 }}
                  to="https://tggl.io/blog/simplifying-tggl-to-enforce-good-practices#what-changes-for-flags-i-create-from-now-on"
                  target="_blank"
                >
                  Learn more
                </Button>
              </Card>
            </Sections.Section>
          )}
        {organization.hasFlagsWithInactiveVariations && <hr />}
        <Sections.Section
          title={
            organization.hasFlagsWithInactiveVariations ? 'Active' : 'Value'
          }
          subTitle={
            <>
              {flagData?.flag?.allowInactiveVariations ? (
                <>
                  {fields.length === 1 ? (
                    <p style={{ marginBottom: 16 }}>
                      This variation is used when specific conditions are met.
                    </p>
                  ) : (
                    <p style={{ marginBottom: 16 }}>
                      Those variations are used when specific conditions are
                      met.
                    </p>
                  )}
                </>
              ) : (
                <p style={{ marginBottom: 16 }}>
                  Choose the type of value you want to use for your variations
                </p>
              )}

              <SelectInput
                value={valuesType}
                onChange={(newType) => {
                  setValue('valuesType', newType)

                  if (newType === ValuesType.NULL) {
                    fields.forEach((_, index) =>
                      setValue(`active.${index}.value`, null, {
                        shouldDirty: true,
                        shouldTouch: true,
                      })
                    )
                  }

                  if (newType === ValuesType.NUMBER) {
                    fields.forEach((_, index) => {
                      const newValue = parseFloat(
                        getValues(`active.${index}.value`)
                      )
                      setValue(
                        `active.${index}.value`,
                        isNaN(newValue) ? 0 : newValue,
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                        }
                      )
                    })
                  }

                  if (newType === ValuesType.STRING) {
                    fields.forEach((_, index) => {
                      const newValue = getValues(`active.${index}.value`)
                      setValue(
                        `active.${index}.value`,
                        newValue !== null ? String(newValue) : '',
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                        }
                      )
                    })
                  }

                  if (newType === ValuesType.JSON) {
                    fields.forEach((_, index) => {
                      const newValue = getValues(`active.${index}.value`)
                      setValue(
                        `active.${index}.value`,
                        JSON.stringify(newValue),
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                        }
                      )
                    })
                  }

                  if (newType === ValuesType.BOOLEAN) {
                    fields.forEach((_, index) => {
                      setValue(
                        `active.${index}.value`,
                        Boolean(getValues(`active.${index}.value`)),
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                        }
                      )
                    })
                  }
                }}
                showSelectedIcon
                options={[
                  ...(flagData?.flag?.allowInactiveVariations
                    ? [
                        {
                          value: ValuesType.NULL,
                          label: 'No value',
                          icon: IconCircleDotted,
                        },
                      ]
                    : []),
                  { value: ValuesType.STRING, label: 'String', icon: IconAbc },
                  { value: ValuesType.NUMBER, label: 'Number', icon: IconHash },
                  {
                    value: ValuesType.BOOLEAN,
                    label: 'Boolean',
                    icon: IconToggleRight,
                  },
                  { value: ValuesType.JSON, label: 'JSON', icon: IconBraces },
                ]}
              />
            </>
          }
        >
          <Reorder.Group
            axis="y"
            values={fields}
            className={style.variationsList}
            onReorder={(newValue) => {
              const moved = getMovedItem(fields as any, newValue as any)

              if (moved) {
                move(moved.from, moved.to)
              }
            }}
            as="div"
          >
            {fields.map((field, index) => (
              <Item
                key={field.id}
                render={(controls) => (
                  <Reorder.Item
                    key={field.id}
                    value={field}
                    layout="position"
                    transition={{
                      type: 'spring',
                      stiffness: 700,
                      damping: 30,
                      mass: 0.8,
                    }}
                    dragListener={false}
                    dragControls={controls}
                    as="div"
                  >
                    <Card key={field.id}>
                      <div className={style.cardHeader}>
                        <InputWrapper
                          placeholder="Name"
                          name={`active.${index}.name`}
                          component={TextInput}
                          control={control}
                          rules={{ required: true }}
                          prefix={
                            <InputWrapper
                              name={`active.${index}.color`}
                              component={VariationColorInput}
                              control={control}
                            />
                          }
                        />
                        {valuesType === ValuesType.BOOLEAN && (
                          <InputWrapper
                            name={`active.${index}.value`}
                            component={SelectInput}
                            control={control}
                            options={[
                              { label: 'True', value: true },
                              { label: 'False', value: false },
                            ]}
                            rules={{
                              required: false,
                              validate: (value) => typeof value === 'boolean',
                            }}
                            placeholder="Value"
                          />
                        )}
                        {valuesType === ValuesType.NUMBER && (
                          <InputWrapper
                            name={`active.${index}.value`}
                            component={FloatInput}
                            control={control}
                            rules={{ required: true, validate: () => true }}
                            placeholder="Value"
                            prefix={
                              <Tooltip>
                                <Tooltip.Trigger
                                  component={IconHash}
                                  size={16}
                                />
                                <Tooltip.Content>
                                  This value will be used inside the code
                                </Tooltip.Content>
                              </Tooltip>
                            }
                          />
                        )}
                        {valuesType === ValuesType.STRING && (
                          <InputWrapper
                            name={`active.${index}.value`}
                            component={TextInput}
                            control={control}
                            rules={{ required: true, validate: () => true }}
                            placeholder="Value"
                            prefix={
                              <Tooltip>
                                <Tooltip.Trigger
                                  component={IconAbc}
                                  size={16}
                                />
                                <Tooltip.Content>
                                  This value will be used inside the code
                                </Tooltip.Content>
                              </Tooltip>
                            }
                          />
                        )}

                        <div
                          className="handler"
                          onPointerDown={(e) => {
                            controls.start(e)
                            e.preventDefault()
                          }}
                        >
                          <IconGripVertical size={18} />
                        </div>

                        <div style={{ flex: 1 }} />

                        <Tooltip>
                          <Tooltip.Trigger
                            component={IconButton}
                            icon={IconTrash}
                            onClick={() => remove(index)}
                            disabled={field.used}
                          />
                          <Tooltip.Content>
                            {field.used
                              ? 'This variation cannot be deleted because it is used in one of the conditions'
                              : 'Delete'}
                          </Tooltip.Content>
                        </Tooltip>
                      </div>
                      <InputWrapper
                        placeholder="Description"
                        name={`active.${index}.description`}
                        component={TextInput}
                        control={control}
                        prefix={
                          <Tooltip>
                            <Tooltip.Trigger
                              component={IconAlignLeft}
                              size={16}
                            />
                            <Tooltip.Content>
                              A short description for your team
                            </Tooltip.Content>
                          </Tooltip>
                        }
                      />
                      {valuesType === ValuesType.JSON && (
                        <InputWrapper
                          name={`active.${index}.value`}
                          component={JsonInput}
                          control={control}
                          rules={{
                            required: false,
                            validate: () => true,
                          }}
                        />
                      )}
                    </Card>
                  </Reorder.Item>
                )}
              />
            ))}
            <Button
              icon={IconPlus}
              onClick={() =>
                append({
                  id: null,
                  name: '',
                  description: '',
                  color: colors[1],
                  used: false,
                  value: null,
                })
              }
            >
              Add variation
            </Button>
          </Reorder.Group>
        </Sections.Section>
      </Sections>
      <motion.div
        className={styleConditions.toolbar}
        initial={{ y: '100%' }}
        animate={{ y: isDirty ? 0 : '100%' }}
      >
        <Toolbar>
          {organization.myAggregatedRole.manageFlags === ManageFlagsRole.All ||
          (organization.myAggregatedRole.manageFlags ===
            ManageFlagsRole.Shared &&
            flagData?.flag?.sharedWithMe) ? (
            <>
              {(project.requireChangeRequest !==
                ChangeRequestRequirement.Always ||
                organization.myAggregatedRole.reviews === ReviewsRole.Bypass ||
                !organization.canMakeReviews) && (
                <Tooltip>
                  <Tooltip.Trigger
                    component="div"
                    children={
                      <Button
                        color="primary"
                        loading={isSubmitting}
                        type="submit"
                        icon={IconCheck}
                        disabled={
                          project.requireChangeRequest !==
                            ChangeRequestRequirement.Never &&
                          organization.myAggregatedRole.reviews !==
                            ReviewsRole.Bypass &&
                          organization.canMakeReviews
                        }
                      >
                        Save changes
                      </Button>
                    }
                  />
                  <Tooltip.Content>
                    {project.requireChangeRequest !==
                      ChangeRequestRequirement.Never &&
                    organization.myAggregatedRole.reviews !==
                      ReviewsRole.Bypass &&
                    organization.canMakeReviews
                      ? 'Changing variations may impact production'
                      : 'Apply changes immediately'}
                  </Tooltip.Content>
                </Tooltip>
              )}
              {organization.canMakeReviews && (
                <Tooltip>
                  <Tooltip.Trigger
                    component={Button}
                    color="success"
                    variant="light"
                    loading={isSubmitting}
                    icon={IconEyeglass}
                    children="Request review"
                    onClick={handleSubmit(saveChangeRequest)}
                  />
                  <Tooltip.Content>
                    Someone else will need to approve these changes before
                    applying them
                  </Tooltip.Content>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <IconAlertTriangle
                size={16}
                style={{ color: 'var(--color-error)' }}
              />{' '}
              You are only a viewer, all changes will be lost
            </>
          )}
        </Toolbar>
      </motion.div>
    </form>
  )
}

export const variationsRoute: RouteObject = {
  path: 'variations',
  element: <Page />,
}
